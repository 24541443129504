import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget13,
  MixedWidget14,
  MixedWidget16, TilesWidget1, TilesWidget2, TilesWidget4
} from "../../../_metronic/partials/widgets";

const DashboardPage = () => (
  <>
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget13
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#FED8E2'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget14
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#BFF1F5'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      <div className='col-xl-4'>
        <MixedWidget16
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#C8D4F4'
          chartHeight='100px'
        />
      </div>
      {/*end::Col*/}
    </div>
    {/*end::Row*/}

    <TilesWidget2 className='mb-5 mb-xl-10' />

    {/*/!*begin::Row*!/*/}
    {/*<div className='row gy-5 g-xl-10'>*/}
    {/*  /!*begin::Col*!/*/}
    {/*  <div className='col-xxl-6'>*/}
    {/*    <ListsWidget5 className='card-xl-stretch mb-xl-10' />*/}
    {/*  </div>*/}
    {/*  /!*end::Col*!/*/}

    {/*  /!*begin::Col*!/*/}
    {/*  <div className='col-xxl-6'>*/}
    {/*    <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} />*/}
    {/*  </div>*/}
    {/*  /!*end::Col*!/*/}
    {/*</div>*/}
  </>
)

const DashboardWrapper = () => {
  return (
    <EnableSidebar>
      <PageTitle description='Navigate supply chains through uncertainty.' breadcrumbs={[]}>
        Dashboard
      </PageTitle>
      <DashboardPage />
    </EnableSidebar>
  )
}

export {DashboardWrapper}
