/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Clients</span>
        </div>
      </div>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Reports'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Risks'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Assessments'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Policies'
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='#'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Suppliers'
        fontIcon='bi-layers'
      />
      <AsideMenuItemWithSub
        to='/crafted/settings'
        title='Settings'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/settings/account' title='Account' hasBullet={true} />
        <AsideMenuItem to='/settings/team' title='Team' hasBullet={true} />
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>

    </>
  )
}
