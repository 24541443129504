import {
  ApolloClient,
  InMemoryCache,
  ApolloLink, HttpLink
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";


const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) console.log(`[Network error]: ${networkError}`);
});


const authMiddleware = new ApolloLink( (operation, forward) => {
  // add the authorization to the headers

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: localStorage.getItem("Token") || ""
    }
  }));

  // return forward(operation);
  return forward(operation).map((data) => {
    // Called after server responds
    const time = new Date().getTime() - operation.getContext().start;
    console.log(`Operation ${operation.operationName} took ${time} to complete`);
    return data;
  });
});

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([errorLink, authMiddleware,   new HttpLink({ uri: process.env.REACT_APP_API_SERVER, credentials: 'include' })]),
  credentials : "include"

});

