import { gql } from "@apollo/client";

export const getUser = gql`
    query getUser($id: UUID!) {
        getUser(id: $id) {
            id
            isRegistered
            firstName
            lastName
            email
            bio
            phoneNumber
            lastLoginAt
        }
    }
`;

