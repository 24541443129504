/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Yup from "yup";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { useSelfService } from "../orly/utils/hooks";
import { kratos } from "../orly/kratos";
import { AxiosError } from "axios";
import { ApolloError, useLazyQuery } from "@apollo/client";
import { getUser } from "../../../../graphql/query/user";
import { SessionAuthenticationMethodMethodEnum } from "@ory/client";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required")
});

const initialValues = {
  email: "",
  password: ""
};

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/


export function Login() {

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();

  const [setUser, { loading: loginLoading, error, data: loginData }] = useLazyQuery(getUser);


  const { flowId, csrfToken } = useSelfService(false, "login");

  console.log("csrfToken", csrfToken);
  console.log("flowId", flowId);


  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        // const {data: auth} = await login(values.email, values.password)

        if (!values || !values.email || !values.password || !flowId) {
          return;
        }

        await kratos.submitSelfServiceLoginFlow(flowId, {
          identifier: values.email,
          csrf_token: csrfToken,
          method: SessionAuthenticationMethodMethodEnum.Password,
          password: values.password
        })     // We logged in successfully! Let's bring the user home.
          .then((res) => {

            if (res.data === undefined) {
              console.log("res.data is undefined", res)
              return;
            }


            setUser({
              variables: {
                id: res.data.session.identity.id
              }
            }).catch((err) => {
              console.log("setUser error", err);
            });

          }).catch((err: ApolloError) => {
            console.log(err);
          })
          .catch((err: AxiosError) => {
            console.log(err);
            switch (err.response?.status) {
              case 400:
                console.log("400 error", err)
                return;
              // This status code is returned when we are trying to
              // validate a session which has not yet completed
              // it's second factor
              case 401:
                console.log("401 error", err)
                // do nothing, the user is not logged in
                return;
              case 403:
                console.log("403 error", err)
                return;
              // This is a legacy error code thrown. See code 422 for
              // more details.
              case 422:
                console.log("422 error", err)
                return;

            }

            Promise.reject(err);
          });

        // await updateLogin();

        // console.log(sessionData.data.session)

        // sessionToken = sessionData.data.session_token;
        // sessionId = sessionData.data.session.id;

        // console.log("THE DATA IS", sessionData.data)
        // saveAuth(auth)
        // const {data: user} = await getUserByToken(auth.api_token)
        // setCurrentUser(user)
        // navigate("/dashboard");
      } catch (error) {
        console.error(error);
        saveAuth(undefined)
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (loginData) {
      console.log("loginData", loginData)
      setCurrentUser(loginData.getUser);
    }
  }, [loginData]);


  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      {/* begin::Heading */}
      <div className="text-center mb-10">
        <h1 className="text-dark mb-3">Sign in to </h1>
        <div className="text-gray-400 fw-bold fs-4">
          New Here?{" "}
          <Link to="/auth/registration" className="link-primary fw-bolder">
            Create an Account
          </Link>
        </div>
      </div>
      {/* begin::Heading */}

      {/*{formik.status ? (*/}
      {/*  <div className='mb-lg-15 alert alert-danger'>*/}
      {/*    <div className='alert-text font-weight-bold'>{formik.status}</div>*/}
      {/*  </div>*/}
      {/*) : (*/}
      {/*  <div className='mb-10 bg-light-info p-8 rounded'>*/}
      {/*    <div className='text-info'>*/}
      {/*      Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to*/}
      {/*      continue.*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Email</label>
        <input
          placeholder="Email"
          {...formik.getFieldProps("email")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.email && formik.errors.email },
            {
              "is-valid": formik.touched.email && !formik.errors.email
            }
          )}
          type="email"
          name="email"
          autoComplete="off"
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span role="alert">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            {/* begin::Label */}
            <label className="form-label fw-bolder text-dark fs-6 mb-0">Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to="/auth/forgot-password"
              className="link-primary fs-6 fw-bolder"
              style={{ marginLeft: "5px" }}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continue</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/*<div className="text-center text-muted text-uppercase fw-bolder mb-5">or</div>*/}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/*<a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">*/}
        {/*  <img*/}
        {/*    alt="Logo"*/}
        {/*    src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")}*/}
        {/*    className="h-20px me-3"*/}
        {/*  />*/}
        {/*  Continue with Google*/}
        {/*</a>*/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/*<a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5">*/}
        {/*  <img*/}
        {/*    alt="Logo"*/}
        {/*    src={toAbsoluteUrl("/media/svg/brand-logos/facebook-4.svg")}*/}
        {/*    className="h-20px me-3"*/}
        {/*  />*/}
        {/*  Continue with Facebook*/}
        {/*</a>*/}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/*<a href="#" className="btn btn-flex flex-center btn-light btn-lg w-100">*/}
        {/*  <img*/}
        {/*    alt="Logo"*/}
        {/*    src={toAbsoluteUrl("/media/svg/brand-logos/apple-black.svg")}*/}
        {/*    className="h-20px me-3"*/}
        {/*  />*/}
        {/*  Continue with Apple*/}
        {/*</a>*/}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  );
}
