import React, {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {LoginSession, UserModel} from './_models'
import * as authHelper from './AuthHelpers'
import {WithChildren} from '../../../../_metronic/helpers'
import { kratosLogout } from "../orly/utils/helpers";
import { kratos } from "../orly/kratos";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getUser } from "../../../../graphql/query/user";
import { updateUser } from "../../../../graphql/mutation/user";


type AuthContextProps = {
  auth: LoginSession | undefined
  saveAuth: (auth: LoginSession | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  Logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  Logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<LoginSession | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: LoginSession | undefined) => {
    setAuth(auth)
    if (auth && auth !== 'loading') {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const Logout = () => {

    kratosLogout(saveAuth);

    saveAuth(undefined)
    setCurrentUser(undefined)

  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, Logout}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const { Logout, setCurrentUser, saveAuth} = useAuth()

  const [setUser, { loading: loginLoading, error, data: loginData }] = useLazyQuery(getUser);
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const [editUser, { loading: registerLoading, error: userError, data: registerData }] = useMutation(updateUser);
  // const {
  //   data: userData,
  //   loading: userLoading,
  //   error: userError,
  // } = useQuery(getUser);
  // We should request user.ts by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {

    (async () => {
      try {
        const response = await kratos.toSession();
        console.log("response.data", response.data)
        if (response.status == 200) {
          console.log("I AM HERE WITH TEH RESPONSE", response.data.identity)

          setUser({
            variables: {
              id: response.data.identity.id
            }
          }).then((res) =>  {
            if (res.error) {
              Logout()
              return false
            }
            if (!res.data.getUser.isRegistered && response.data.active) {
              editUser({
                variables: {
                  id: response.data.identity.id,
                  isRegistered: true,
                }
              }).then((editRes) => {
                console.log(editRes.data)
                setCurrentUser(editRes.data.updateUser)
                return;
              }).catch((err) => {
                console.log("setUser error", err);
              });
            }
            console.log("res.data", res.data)
            saveAuth(response.data)
            setCurrentUser(res.data.getUser)
          }).catch((err) => {
            console.log("setUser error", err);
          });
        }


      } catch (e) {
        return false;
      }


    })();

    setShowSplashScreen(false)

    // if (userError) return
    //
    // console.log("I AM HERE")
    // if (userLoading) return
    //
    // console.log("I AM HERE111")
    // console.log(userData)
    // if (userData.id !== '') {
    //   setCurrentUser(userData)
    //   setShowSplashScreen(false)
    // } else {
    //   Logout()
    //   setShowSplashScreen(false)
    // }
    // const requestUser = async (apiToken: string) => {
    //   try {
    //     if (!didRequest.current) {
    //       const {data} = await getUserByToken(apiToken)
    //       if (data) {
    //         setCurrentUser(data)
    //       }
    //     }
    //   } catch (error) {
    //     console.error(error)
    //     if (!didRequest.current) {
    //       Logout()
    //     }
    //   } finally {
    //     setShowSplashScreen(false)
    //   }
    //
    //   return () => (didRequest.current = true)
    // }
    //
    // if (auth && auth) {
    //   requestUser(auth.api_token)
    // } else {
    //   Logout()
    //   setShowSplashScreen(false)
    // }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
