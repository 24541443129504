import { FC } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/helpers";

const VerifyEmail: FC = () => {

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="d-flex flex-column flex-center text-center p-10">
          <div className="card card-flush w-lg-650px py-5">
            <div className="card-body py-15 py-lg-20">
              <div className="mb-14">
                {/* begin::Illustration */}
                <img
                  alt="Logo"
                  src={toAbsoluteUrl("/media/logos/logo.png")}
                  className="theme-light-show h-45px"
                ></img>
                {/* end::Illustration */}
              </div>

              {/* begin::Message */}
              <h1 className="fw-bolder text-gray-900 mb-5" style={{ color: "#A3A3C7" }}>
                Verify your email
              </h1>
              <div className="mb-11">
                {/* begin::Link */}
                <Link to="/" className="btn btn-sm btn-primary">
                  Verify your email
                </Link>
                {/* end::Link */}
              </div>
              {/* end::Message */}

              <div className="fs-6 mb-8">
              <span className="fw-semibold text-gray-500">
                Didn’t receive an email?
              </span>

              </div>
              <div className="mb-11">
                {/* begin::Link */}
                <Link to="/" className="btn btn-sm btn-primary">
                  Try Again
                </Link>
                {/* end::Link */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { VerifyEmail };