import { gql } from "@apollo/client";

export const createUser = gql`
    mutation createUser($id: UUID, $firstName: Name!, $lastName: Name!, $email: Email!, $password: String!, $companyId: UUID!) {
        createUser(input:{
            id: $id,
            firstName:$firstName,
            lastName:$lastName, 
            email:$email,
            password:$password
            companyId:$companyId}
        ) {
            id
            isRegistered
            firstName
            lastName
            email
            bio
            phoneNumber
            lastLoginAt
            updatedAt
        }
    }

`;



export const updateUser = gql`
    mutation updateUser($id: UUID!, $isRegistered: Boolean){
        updateUser(input:{
            id:$id,
            isRegistered: $isRegistered,
#            firstName: $firstName,
#            lastName: $lastName,
#            password: $password,
#            companyId: $companyId,
        }) {
            id
            isRegistered
            firstName
            lastName
            email
            bio
            phoneNumber
            lastLoginAt
            updatedAt
        }
    }
`;