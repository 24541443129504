import { gql } from "@apollo/client";

export const deactivateAccount = gql`
    mutation deleteAccount {
        deactivateUserAndCompany(input: "disabled") 
        }

`;



export const createCompanyAndUser = gql`
    mutation createCompanyAndUser(
        $id: UUID,  $firstName: Name!, $lastName: Name!, $email: Email!, $password: String!
        $companyName: String!, $displayName: String,  $companyEmail: String!, $registrationNumber: String, $phoneNumber: PhoneNumber,
        $lineAddress1: String!, $city: String!, $state: String!, $country: String!, $contactFirstName: Name!,
        $contactLastName: Name!, $zipCode: String!
    ) {
        createCompanyAndUser(input:{ user:{
            id: $id, firstName: $firstName, lastName:$lastName email:$email , password:$password
        }company:{
            name:$companyName, displayName: $displayName  email:$companyEmail registrationNumber:$registrationNumber, phoneNumber:$phoneNumber
            address:{addressLine1: $lineAddress1, city:$city, state:$state, country:$country, contactFirstName:$contactFirstName contactLastName:$contactLastName,
                zipCode:$zipCode, phoneNumber:$phoneNumber
            }
        }}) {
            company {
                id
            }
            user {
                id
                email
                firstName
                lastName
                bio
                phoneNumber
                isRegistered
                avatarImageUrl
                createdAt
            }
        }
    }

`;


