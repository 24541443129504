import { Configuration, V0alpha2Api } from "@ory/client";
import config from "./config/config";

export const kratos = new V0alpha2Api(
  new Configuration({
    basePath: process.env.REACT_APP_ORY_SDK_URL || "http://localhost:4433",
    baseOptions: {
      withCredentials: true,
    },
  }),
  config.identity_server_url
);