import { createRoot } from "react-dom/client";
// GraphQL
import { ApolloProvider } from "@apollo/client";
import { client } from "./graphql/client";
// Axios
import axios from "axios";
import { Chart, registerables } from "chart.js";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ReactGA from 'react-ga';

// Apps
import { MetronicI18nProvider } from "./_metronic/i18n/Metronici18n";
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import "./_metronic/assets/sass/plugins.scss";
import "./_metronic/assets/sass/style.scss";
import "./_metronic/assets/sass/style.react.scss";

import { AppRoutes } from "./app/routing/AppRoutes";
import { AuthProvider } from "./app/modules/auth";
import { ThemeModeProvider } from "./_metronic/partials/layout/theme-mode/ThemeModeProvider";
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
// setupAxios(axios);

// tracking code
ReactGA.initialize('G-YDQXM8S2DR');
ReactGA.pageview(window.location.pathname + window.location.search);


Chart.register(...registerables);

const queryClient = new QueryClient();
const container = document.getElementById("root");
if (container) {
  createRoot(container).render(
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <MetronicI18nProvider>
          <ThemeModeProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ThemeModeProvider>
        </MetronicI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ApolloProvider>
  );
}
