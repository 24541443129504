/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useAuth } from "../core/Auth";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import {
  useSelfService
} from "../orly/utils/hooks";
import { kratos } from "../orly/kratos";
import { useMutation } from "@apollo/client";
import { createUser } from "../../../../graphql/mutation/user";
import { createCompany } from "../../../../graphql/mutation/company";
import { createCompanyAndUser } from "../../../../graphql/mutation/account";

const initialValues = {
  jobtitle: "",
  registerCompanyName: "",
  displayCompanyName: "",
  registerAddressLine1: "",
  registerAddressCity: "",
  registerAddressState: "",
  registerAddressZipCode: "",
  registerAddressCountry: ""
};

const registrationSchema = Yup.object().shape({
  jobtitle: Yup.string()
    .min(1, "Minimum 1 characters")
    .max(50, "Maximum 50 characters")
    .required("Job title is required"),
  registerCompanyName: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Company name is required"),
  registerAddressLine1: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("Address is required"),
  registerAddressCity: Yup.string()
    .min(3, "Minimum 2 characters")
    .max(50, "Maximum 50 characters")
    .required("City is required"),
  registerAddressState: Yup.string()
    .min(3, "Minimum 3 characters")
    .max(50, "Maximum 50 characters")
    .required("State is required"),
  registerAddressZipCode: Yup.string()
    .min(3, "Minimum 2 characters")
    .max(50, "Maximum 50 characters")
    .required("Zip Code is required"),
  registerAddressCountry: Yup.string()
    .min(2, "Minimum 2 characters")
    .max(50, "Maximum 10 characters")
    .required("Country is required")

});

let userSettings: { email: string; first_name: string; last_name: string, password: string };

export function RegisterCompany() {
  const { flowId, csrfToken } = useSelfService(false, "registration");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();


  // const [registerUser, { loading: registerLoading, error: userError, data: registerData }] = useMutation(createUser);
  // const [registerCompany, {
  //   loading: registerCompanyLoading,
  //   error: companyError,
  //   data: registerCompanyData
  // }] = useMutation(createCompany);
  const [registerCompanyAndUser, {
    loading: registerLoading,
    error: userError,
    data: registerData
  }] = useMutation(createCompanyAndUser);


  const location = useLocation();


  if (location.state) {
    userSettings = location.state as {
      email: string
      first_name: string
      last_name: string
      password: string
    };
  }


  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        if (
          !values ||
          !values.registerCompanyName ||
          !values.registerAddressLine1 ||
          !values.registerAddressCity ||
          !values.registerAddressZipCode ||
          !values.registerAddressState ||
          !flowId
        ) {
          return;
        }

        await kratos.submitSelfServiceRegistrationFlow(flowId, {
          provider: "",
          traits: {
            email: userSettings.email,
            name: {
              first: userSettings.first_name,
              last: userSettings.last_name
            }
          },
          password: userSettings.password,
          method: "password",
          csrf_token: csrfToken
        }).then((res) => {
          const { session } = res.data;
          console.log(session);

          if (session === undefined) {
            console.log("SESSION IS UNDEFINED", session);
            return;
          }

          saveAuth(session);
          // registerCompany({
          //   variables: {
          //     name: values.registerCompanyName,
          //     displayName: values.displayCompanyName,
          //     email: userSettings.email,
          //     lineAddress1: values.registerAddressLine1,
          //     city: values.registerAddressCity,
          //     state: values.registerAddressState,
          //     country: values.registerAddressCountry,
          //     contactFirstName: userSettings.first_name,
          //     contactLastName: userSettings.last_name,
          //     zipCode: values.registerAddressZipCode
          //
          //   }
          // }).then((comp) => {
          //   registerUser({
          //     variables: {
          //       id: res.data.identity.id,
          //       firstName: userSettings.first_name,
          //       lastName: userSettings.last_name,
          //       email: userSettings.email,
          //       password: userSettings.password,
          //       companyId: comp.data.createCompany.id
          //     }
          //   }).catch((err) => {
          //     console.log("setUser error", err);
          //   });
          //
          // });
          // $id: UUID,  $firstName: Name!, $lastName: Name!, $email: Email!, $password: String!
          // $companyName: String!, $displayName: String,  $companyEmail: String!, $registrationNumber: String, $phoneNumber: PhoneNumber,
          //   $lineAddress1: String!, $city: String!, $state: String!, $country: String!, $contactFirstName: Name!,
          //   $contactLastName: Name!, $zipCode: String!
          registerCompanyAndUser({
            variables: {
              id: res.data.identity.id,
              firstName: userSettings.first_name,
              lastName: userSettings.last_name,
              email: userSettings.email,
              password: userSettings.password,
              companyName: values.registerCompanyName,
              displayName: values.displayCompanyName,
              companyEmail: userSettings.email,
              lineAddress1: values.registerAddressLine1,
              city: values.registerAddressCity,
              state: values.registerAddressState,
              country: values.registerAddressCountry,
              contactFirstName: userSettings.first_name,
              contactLastName: userSettings.last_name,
              zipCode: values.registerAddressZipCode
            }
          }).then((res) => {
            console.log("RES DATA", res.data)
          }).catch((err) => {
            console.log("setUser error", err);
          });


        });

        // await VerifyInKratos(userSettings.email)

      } catch (error) {
        console.error(error);
        saveAuth(undefined);
        setStatus("The registration details is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    }
  });

  useEffect(() => {
    if (registerData) {
      setCurrentUser(registerData.createCompanyAndUser.user);
    }
  }, [registerData]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
  }, []);

  return (
    <form
      className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      noValidate
      id="kt_login_signup_form"
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className="mb-10 text-center">
        {/* begin::Title */}
        <h1 className="text-dark mb-3">Complete Setup</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className="mb-lg-15 alert alert-danger">
          <div className="alert-text font-weight-bold">{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Job Title */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Job title</label>
        <input
          placeholder="Procurement Manager"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("jobtitle")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.jobtitle && formik.errors.jobtitle },
            {
              "is-valid": formik.touched.jobtitle && !formik.errors.jobtitle
            }
          )}
        />
        {formik.touched.jobtitle && formik.errors.jobtitle && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.jobtitle}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Company Register Name */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered company name</label>
        <input
          placeholder="Telsa"
          type="Tesla Motors Inc."
          autoComplete="off"
          {...formik.getFieldProps("registerCompanyName")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.registerCompanyName && formik.errors.registerCompanyName },
            {
              "is-valid": formik.touched.registerCompanyName && !formik.errors.registerCompanyName
            }
          )}
        />
        {formik.touched.registerCompanyName && formik.errors.registerCompanyName && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.registerCompanyName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Company Display Name */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Company display name (optional)</label>
        <input
          placeholder="Telsa"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("displayCompanyName")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.displayCompanyName && formik.errors.displayCompanyName },
            {
              "is-valid": formik.touched.displayCompanyName && !formik.errors.displayCompanyName
            }
          )}
        />
        {formik.touched.displayCompanyName && formik.errors.displayCompanyName && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.displayCompanyName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group Address Line 1 */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered Address Line 1</label>
        <input
          placeholder="123 5th Ave."
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("registerAddressLine1")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.registerAddressLine1 && formik.errors.registerAddressLine1 },
            {
              "is-valid": formik.touched.registerAddressLine1 && !formik.errors.registerAddressLine1
            }
          )}
        />
        {formik.touched.registerAddressLine1 && formik.errors.registerAddressLine1 && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.registerAddressLine1}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group Address City */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered City</label>
        <input
          placeholder="New York City"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("registerAddressCity")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.registerAddressCity && formik.errors.registerAddressCity },
            {
              "is-valid": formik.touched.registerAddressCity && !formik.errors.registerAddressCity
            }
          )}
        />
        {formik.touched.registerAddressCity && formik.errors.registerAddressCity && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.registerAddressCity}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}


      {/* begin::Form group Address State */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered State</label>
        <input
          placeholder="New York"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("registerAddressState")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.registerAddressState && formik.errors.registerAddressState },
            {
              "is-valid": formik.touched.registerAddressState && !formik.errors.registerAddressState
            }
          )}
        />
        {formik.touched.registerAddressState && formik.errors.registerAddressState && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.registerAddressState}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Address Zip Code */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered Zip Code</label>
        <input
          placeholder="10005"
          type="text"
          autoComplete="off"
          {...formik.getFieldProps("registerAddressZipCode")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.registerAddressZipCode && formik.errors.registerAddressZipCode },
            {
              "is-valid": formik.touched.registerAddressZipCode && !formik.errors.registerAddressZipCode
            }
          )}
        />
        {formik.touched.registerAddressZipCode && formik.errors.registerAddressZipCode && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.registerAddressZipCode}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Address Country */}
      <div className="fv-row mb-7">
        <label className="form-label fw-bolder text-dark fs-6">Registered Country</label>

        <div className="col-lg-8 fv-row">
          <select
            className="form-select form-select-solid form-select-lg fw-bold"
            {...formik.getFieldProps("registerAddressCountry")}
          >
            <option value="">Select a Country...</option>
            <option value="AF">Afghanistan</option>
            <option value="AX">Aland Islands</option>
            <option value="AL">Albania</option>
            <option value="DZ">Algeria</option>
            <option value="AS">American Samoa</option>
            <option value="AD">Andorra</option>
            <option value="AO">Angola</option>
            <option value="AI">Anguilla</option>
            <option value="AQ">Antarctica</option>
            <option value="AG">Antigua and Barbuda</option>
            <option value="AR">Argentina</option>
            <option value="AM">Armenia</option>
            <option value="AW">Aruba</option>
            <option value="AU">Australia</option>
            <option value="AT">Austria</option>
            <option value="AZ">Azerbaijan</option>
            <option value="BS">Bahamas</option>
            <option value="BH">Bahrain</option>
            <option value="BD">Bangladesh</option>
            <option value="BB">Barbados</option>
            <option value="BY">Belarus</option>
            <option value="BE">Belgium</option>
            <option value="BZ">Belize</option>
            <option value="BJ">Benin</option>
            <option value="BM">Bermuda</option>
            <option value="BT">Bhutan</option>
            <option value="BO">Bolivia, Plurinational State of</option>
            <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
            <option value="BA">Bosnia and Herzegovina</option>
            <option value="BW">Botswana</option>
            <option value="BV">Bouvet Island</option>
            <option value="BR">Brazil</option>
            <option value="IO">British Indian Ocean Territory</option>
            <option value="BN">Brunei Darussalam</option>
            <option value="BG">Bulgaria</option>
            <option value="BF">Burkina Faso</option>
            <option value="BI">Burundi</option>
            <option value="KH">Cambodia</option>
            <option value="CM">Cameroon</option>
            <option value="CA">Canada</option>
            <option value="CV">Cape Verde</option>
            <option value="KY">Cayman Islands</option>
            <option value="CF">Central African Republic</option>
            <option value="TD">Chad</option>
            <option value="CL">Chile</option>
            <option value="CN">China</option>
            <option value="CX">Christmas Island</option>
            <option value="CC">Cocos (Keeling) Islands</option>
            <option value="CO">Colombia</option>
            <option value="KM">Comoros</option>
            <option value="CG">Congo</option>
            <option value="CD">Congo, the Democratic Republic of the</option>
            <option value="CK">Cook Islands</option>
            <option value="CR">Costa Rica</option>
            <option value="CI">Côte d'Ivoire</option>
            <option value="HR">Croatia</option>
            <option value="CU">Cuba</option>
            <option value="CW">Curaçao</option>
            <option value="CY">Cyprus</option>
            <option value="CZ">Czech Republic</option>
            <option value="DK">Denmark</option>
            <option value="DJ">Djibouti</option>
            <option value="DM">Dominica</option>
            <option value="DO">Dominican Republic</option>
            <option value="EC">Ecuador</option>
            <option value="EG">Egypt</option>
            <option value="SV">El Salvador</option>
            <option value="GQ">Equatorial Guinea</option>
            <option value="ER">Eritrea</option>
            <option value="EE">Estonia</option>
            <option value="ET">Ethiopia</option>
            <option value="FK">Falkland Islands (Malvinas)</option>
            <option value="FO">Faroe Islands</option>
            <option value="FJ">Fiji</option>
            <option value="FI">Finland</option>
            <option value="FR">France</option>
            <option value="GF">French Guiana</option>
            <option value="PF">French Polynesia</option>
            <option value="TF">French Southern Territories</option>
            <option value="GA">Gabon</option>
            <option value="GM">Gambia</option>
            <option value="GE">Georgia</option>
            <option value="DE">Germany</option>
            <option value="GH">Ghana</option>
            <option value="GI">Gibraltar</option>
            <option value="GR">Greece</option>
            <option value="GL">Greenland</option>
            <option value="GD">Grenada</option>
            <option value="GP">Guadeloupe</option>
            <option value="GU">Guam</option>
            <option value="GT">Guatemala</option>
            <option value="GG">Guernsey</option>
            <option value="GN">Guinea</option>
            <option value="GW">Guinea-Bissau</option>
            <option value="GY">Guyana</option>
            <option value="HT">Haiti</option>
            <option value="HM">Heard Island and McDonald Islands</option>
            <option value="VA">Holy See (Vatican City State)</option>
            <option value="HN">Honduras</option>
            <option value="HK">Hong Kong</option>
            <option value="HU">Hungary</option>
            <option value="IS">Iceland</option>
            <option value="IN">India</option>
            <option value="ID">Indonesia</option>
            <option value="IR">Iran, Islamic Republic of</option>
            <option value="IQ">Iraq</option>
            <option value="IE">Ireland</option>
            <option value="IM">Isle of Man</option>
            <option value="IL">Israel</option>
            <option value="IT">Italy</option>
            <option value="JM">Jamaica</option>
            <option value="JP">Japan</option>
            <option value="JE">Jersey</option>
            <option value="JO">Jordan</option>
            <option value="KZ">Kazakhstan</option>
            <option value="KE">Kenya</option>
            <option value="KI">Kiribati</option>
            <option value="KP">Korea, Democratic People's Republic of</option>
            <option value="KW">Kuwait</option>
            <option value="KG">Kyrgyzstan</option>
            <option value="LA">Lao People's Democratic Republic</option>
            <option value="LV">Latvia</option>
            <option value="LB">Lebanon</option>
            <option value="LS">Lesotho</option>
            <option value="LR">Liberia</option>
            <option value="LY">Libya</option>
            <option value="LI">Liechtenstein</option>
            <option value="LT">Lithuania</option>
            <option value="LU">Luxembourg</option>
            <option value="MO">Macao</option>
            <option value="MK">Macedonia, the former Yugoslav Republic of</option>
            <option value="MG">Madagascar</option>
            <option value="MW">Malawi</option>
            <option value="MY">Malaysia</option>
            <option value="MV">Maldives</option>
            <option value="ML">Mali</option>
            <option value="MT">Malta</option>
            <option value="MH">Marshall Islands</option>
            <option value="MQ">Martinique</option>
            <option value="MR">Mauritania</option>
            <option value="MU">Mauritius</option>
            <option value="YT">Mayotte</option>
            <option value="MX">Mexico</option>
            <option value="FM">Micronesia, Federated States of</option>
            <option value="MD">Moldova, Republic of</option>
            <option value="MC">Monaco</option>
            <option value="MN">Mongolia</option>
            <option value="ME">Montenegro</option>
            <option value="MS">Montserrat</option>
            <option value="MA">Morocco</option>
            <option value="MZ">Mozambique</option>
            <option value="MM">Myanmar</option>
            <option value="NA">Namibia</option>
            <option value="NR">Nauru</option>
            <option value="NP">Nepal</option>
            <option value="NL">Netherlands</option>
            <option value="NC">New Caledonia</option>
            <option value="NZ">New Zealand</option>
            <option value="NI">Nicaragua</option>
            <option value="NE">Niger</option>
            <option value="NG">Nigeria</option>
            <option value="NU">Niue</option>
            <option value="NF">Norfolk Island</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="NO">Norway</option>
            <option value="OM">Oman</option>
            <option value="PK">Pakistan</option>
            <option value="PW">Palau</option>
            <option value="PS">Palestinian Territory, Occupied</option>
            <option value="PA">Panama</option>
            <option value="PG">Papua New Guinea</option>
            <option value="PY">Paraguay</option>
            <option value="PE">Peru</option>
            <option value="PH">Philippines</option>
            <option value="PN">Pitcairn</option>
            <option value="PL">Poland</option>
            <option value="PT">Portugal</option>
            <option value="PR">Puerto Rico</option>
            <option value="QA">Qatar</option>
            <option value="RE">Réunion</option>
            <option value="RO">Romania</option>
            <option value="RU">Russian Federation</option>
            <option value="RW">Rwanda</option>
            <option value="BL">Saint Barthélemy</option>
            <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
            <option value="KN">Saint Kitts and Nevis</option>
            <option value="LC">Saint Lucia</option>
            <option value="MF">Saint Martin (French part)</option>
            <option value="PM">Saint Pierre and Miquelon</option>
            <option value="VC">Saint Vincent and the Grenadines</option>
            <option value="WS">Samoa</option>
            <option value="SM">San Marino</option>
            <option value="ST">Sao Tome and Principe</option>
            <option value="SA">Saudi Arabia</option>
            <option value="SN">Senegal</option>
            <option value="RS">Serbia</option>
            <option value="SC">Seychelles</option>
            <option value="SL">Sierra Leone</option>
            <option value="SG">Singapore</option>
            <option value="SX">Sint Maarten (Dutch part)</option>
            <option value="SK">Slovakia</option>
            <option value="SI">Slovenia</option>
            <option value="SB">Solomon Islands</option>
            <option value="SO">Somalia</option>
            <option value="ZA">South Africa</option>
            <option value="GS">South Georgia and the South Sandwich Islands</option>
            <option value="KR">South Korea</option>
            <option value="SS">South Sudan</option>
            <option value="ES">Spain</option>
            <option value="LK">Sri Lanka</option>
            <option value="SD">Sudan</option>
            <option value="SR">Suriname</option>
            <option value="SJ">Svalbard and Jan Mayen</option>
            <option value="SZ">Swaziland</option>
            <option value="SE">Sweden</option>
            <option value="CH">Switzerland</option>
            <option value="SY">Syrian Arab Republic</option>
            <option value="TW">Taiwan, Province of China</option>
            <option value="TJ">Tajikistan</option>
            <option value="TZ">Tanzania, United Republic of</option>
            <option value="TH">Thailand</option>
            <option value="TL">Timor-Leste</option>
            <option value="TG">Togo</option>
            <option value="TK">Tokelau</option>
            <option value="TO">Tonga</option>
            <option value="TT">Trinidad and Tobago</option>
            <option value="TN">Tunisia</option>
            <option value="TR">Turkey</option>
            <option value="TM">Turkmenistan</option>
            <option value="TC">Turks and Caicos Islands</option>
            <option value="TV">Tuvalu</option>
            <option value="UG">Uganda</option>
            <option value="UA">Ukraine</option>
            <option value="AE">United Arab Emirates</option>
            <option value="GB">United Kingdom</option>
            <option value="US">United States</option>
            <option value="UY">Uruguay</option>
            <option value="UZ">Uzbekistan</option>
            <option value="VU">Vanuatu</option>
            <option value="VE">Venezuela, Bolivarian Republic of</option>
            <option value="VN">Vietnam</option>
            <option value="VI">Virgin Islands</option>
            <option value="WF">Wallis and Futuna</option>
            <option value="EH">Western Sahara</option>
            <option value="YE">Yemen</option>
            <option value="ZM">Zambia</option>
            <option value="ZW">Zimbabwe</option>
          </select>
          {formik.touched.registerAddressCountry && formik.errors.registerAddressCountry && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.registerAddressCountry}</div>
            </div>
          )}
        </div>
      </div>
      {/* end::Form group */}


      {/* begin::Form group */}
      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_up_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Submit</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Please wait...{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
        <Link to="/auth/register">
          <button
            type="button"
            id="kt_login_signup_form_cancel_button"
            className="btn btn-lg btn-light-primary w-100 mb-5"
          >
            Cancel
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  );
}
